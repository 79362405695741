// import axios, { AxiosRequestConfig } from 'axios';
// import { Modal } from 'antd';
// import { BASE_URL } from 'config/constants';
// import { openNotificationWithIcon } from './helpers';

// const axiosInstance = axios.create({
//   baseURL: BASE_URL,
//   headers: {
//     'Content-Type': 'application/json',
//   },
// });

// const { confirm } = Modal;

// interface Request {
//   method: string;
//   endpoint: string;
//   body?: any;
//   withoutToken?: boolean;
//   context?: string;
//   successToast?: string;
//   action?: string;
// }

// export const fetchData = async (request: Request): Promise<any> => {
//   const { method, endpoint, body, withoutToken, context, successToast, action } = request;

//   let tokenStr = '';

//   if (!withoutToken) {
//     try {
//       const session = await Auth.currentSession();
//       tokenStr = session.getIdToken().getJwtToken();
//     } catch (error) {
//       await Auth.signOut();
//       console.log(error)
//     }
//   }

//   const config: AxiosRequestConfig = {
//     method,
//     url: endpoint,
//     data: body,
//   };

//   if (tokenStr) {
//     config.headers = {
//       ...config.headers,
//       Authorization: `Bearer ${tokenStr}`,
//     };
//   }

//   if ([action, method.toLowerCase()].includes('delete')) {
//     // Show confirm popup for delete operation
//     return new Promise(async (resolve, reject) => {
//       confirm({
//         title: 'Confirm Delete',
//         className: 'confirm-modal',
//         content: `Are you sure you want to delete this ${context}?`,
//         onOk: async () => {
//           try {
//             const response = await axiosInstance(config);
//             resolve(response.data || true);
//           } catch (error: any) {
//             handleApiError(error, reject);
//           }
//         },
//         onCancel: () => { },
//       });
//     });
//   } else {
//     // For other operations, proceed with the request
//     try {
//       const response = await axiosInstance(config);
//       if(successToast) {
//         openNotificationWithIcon(successToast, 'success', "Success")
//       }
//       return response.data || true;
//     } catch (error: any) {
//       handleApiError(error);
//     }
//   }
// };


// const handleApiError = (error: any, reject?: (reason?: any) => void) => {
//   const status = error?.response?.status;
//   if (status === 401 || status === 403) {
//     Auth.signOut();
//   }
//   openNotificationWithIcon(`${error?.response?.data?.message || error?.message}`);
//   if (reject) {
//     console.log(error);
//   } else {
//     console.log(error);
//   }
// };


import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

interface Request {
  method: string;
  endpoint: string;
  body?: Record<string, any>;
}

export const fetchData = async (request: Request): Promise<any> => {
  const { method, endpoint, body } = request;
  const sessiontoken = localStorage.getItem('sessiontoken');
  const requestModel: AxiosRequestConfig = {
    method,
    url: endpoint,
    data: body
  };
  if (sessiontoken)
    requestModel.headers = {
      sessiontoken: sessiontoken
    };
  try {
    const response: AxiosResponse<any> = await axios.request(requestModel);
    return response.data;
  } catch (e:any) {
    return {
      status: e.response?.status,
      data: e.response?.data
    }
  }
};