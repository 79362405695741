import { colors } from "assets/styles/colors";
import { Grid } from "assets/styles/globalStyles";
import { FontSizes } from "utils/enums";
import styled from "styled-components";


export const FooterOuter = styled.div`
        width:100%;
        padding:30px;
  `;


export const Copyright = styled.p`
  margin-top:20px;
  font-size:13px;
  color:#fff;
  text-align:center;
`;

export const SocialMediaWrapper = styled.div`
  max-width: 50vw;
  margin: 0 auto;
  margin-top:20px;
  ul{
      display:flex;
      align-items:center;
      justify-content: center;
      list-style:none;
      margin:0;
      padding:0;
      gap:10px;
      li{
          background: #fff;
          border-radius: 40px;
          height: 25px;
          width: 25px;
          font-size: 13px;
          display:flex;
          align-items:center;
          justify-content: center;
          color: #000;
          svg{
              fill: #000;
              font-size: 10px;
              width: 12px;
              height: 12px;
          }
      }
  }
`;

export const FooterMenu = styled.div`
display:flex;
align-items:center;
justify-content: center;
gap:30px;
text-align:center;
color:#fff;
padding-top:30px;
@media (max-width: 556px) {
    flex-direction:column;
    gap:10px;
}
a{
    color:#fff;
    font-size:15px;
}
`