export const colors = {
    primary: '#794BA3',
    blue3: '#91B6F0',
    blue2: '#C2D6F7',
    blue1: '#E6EEFC',
    blue5: '#F8FAFE',
    blue4: '#2E4264',
    darkPrimary: '#02317D',
    darkPrimary2: '#01193F',
    primaryText: '#222222',
    error: '#FF4D4F',
    success: '#52C41A',
    success2: 'rgb(135 208 104 / 50%)',
    orange: 'rgba(250, 187, 5, 0.28)',
    secondaryText: '#666666',
    grey1: '#888888',
    grey2: '#E1E1E1',
    grey3: '#F9F9F9',
    grey4: '#F1F1F1',
    grey5: '#d9d9d9',
    grey6: '#7C7C7C',
    border: '#F1F1F1',
    primaryDark:'#4d2671',
}