// Core
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    MenuOutlined,
    UserOutlined,
    DownOutlined,
    HomeOutlined,

} from "@ant-design/icons";
// Assets

// Styles
import Button from '../Button';
import { DrawerItems, HeaderOuter, HeaderWrapper, LogoText, RightMenu, TopMenu } from './styles';
import { Avatar, Drawer, Dropdown, Flex } from 'antd';
import Login from 'modals/Login';
import { APP_ID } from 'config/constants';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import i18n from 'locales/i18n';

import Logo from 'assets/images/logo.png'
import DrLogo from 'assets/images/dreamslogo.png'

import type { MenuProps } from 'antd';

const Header: React.FC = () => {
    const { userDetails, currentLang } = useSelector((store: any) => store.commonData);
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const [loginShow, setLogin] = useState(false);
    const [currLng, setCurrLng] = useState('ENG')

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const handleLogin = () => {
        setLogin(true)
    }

    const handleChangeLang = async (value: any) => {
        console.log("vale", value);

        setCurrLng(value === 'ar' ? 'عربي' : 'ENG')
        i18n.changeLanguage(value);
        dispatch({
            type: 'currentLang',
            value: { lang: value, id: value === 'ar' ? 1 : 2 }
        });
        if (value === 'ar') {
            document.body.classList.add('arabic');
        }
        else {
            document.body.classList.remove('arabic')
        }
        const localDetails = localStorage.getItem('userDetails');
        if (localDetails) {
            const formData = new FormData();
            formData.append('language', value);
            formData.append('appId', APP_ID);
            const res = await fetchData(restAPIs.changeLanguage(formData));
            if (res.statusCode === 200) {
                dispatch({
                    type: 'userDetails',
                    value: { ...userDetails, language: res.language }
                });
            }
        }
    };


    useEffect(() => {
        const localDetails = localStorage.getItem('userDetails');
        if (currentLang.lang === 'ar') {
            document.body.classList.add('arabic');
        }
        else {
            document.body.classList.remove('arabic')
        }

        if (localDetails) {
            checkSubsription();
        }
        else {
            i18n.changeLanguage(currentLang.lang);
        }
    }, []);


    const checkSubsription = async () => {
        const formD = new FormData();
        formD.append('appId', APP_ID)
        const res = await fetchData(restAPIs.subscriptionForm(formD));
        dispatch({
            type: 'userDetails',
            value: { ...res, language: res.language == "1" ? 'ar' : 'en' }
        });
        localStorage.setItem('userDetails', JSON.stringify(res));
        localStorage.setItem('sessiontoken', res.sessionToken);
        i18n.changeLanguage(res.language == "1" ? 'ar' : 'en');
        dispatch({
            type: 'currentLang',
            value: { lang: res.language == "1" ? 'ar' : 'en', id: +res.language }
        });

        if (res.language == "1") {
            document.body.classList.add('arabic');
        }
        else {
            document.body.classList.remove('arabic')
        }

    }

    const items: MenuProps['items'] = [
        {
            label: <a onClick={() => handleChangeLang('ar')} className={currLng === 'عربي' ? '__activ_lng' : ''}>عربي (Arabic)</a>,
            key: '0',
        },
        {
            label: <a onClick={() => handleChangeLang('en')} className={currLng === 'ENG' ? '__activ_lng' : ''}>ENG (English)</a>,
            key: '1',
        }
    ];


    return (
        <HeaderOuter >
            <div className='container'>
                <HeaderWrapper>
                    <LogoText >
                        <MenuOutlined onClick={showDrawer} />
                        <NavLink to={'home'} end><img src={DrLogo} alt='Ahlamokom' /></NavLink>
                    </LogoText>
                    <TopMenu direction={(currentLang?.id) == 1 ? "rtl" : "ltr"}>
                        <NavLink to={'about'} end >{t('about_us')}</NavLink>
                        <NavLink to={'dreams'} end>{t('dreams_dictionary')}</NavLink>
                        <NavLink to={'books'} end>{t('books')}</NavLink>
                        <NavLink to={'contact'} end>{t('contact_us')}</NavLink>
                    </TopMenu>
                    <RightMenu direction={(currentLang?.id) == 1 ? "rtl" : "ltr"}>
                        <Dropdown menu={{ items }} trigger={['click']}>
                            <a onClick={(e) => e.preventDefault()}>
                                {currLng}
                                <DownOutlined />
                            </a>
                        </Dropdown>
                        {userDetails?.fullname ? <NavLink to={'settings'} end style={{ color: '#fff', textDecoration: 'none' }}><Avatar src={<img src={userDetails?.profile_pic} alt={userDetails?.fullname} />} /></NavLink> : <button onClick={() => setLogin(true)}><UserOutlined /></button>}
                    </RightMenu>




                </HeaderWrapper>
            </div>
            <Drawer placement='left' onClose={onClose} open={open} className='__drawer' width={290}>
                <DrawerItems direction={(currentLang?.id) == 1 ? "rtl" : "ltr"}>
                    <NavLink to={'home'} end onClick={onClose}><HomeOutlined /> {t('home')}</NavLink>
                    <NavLink to={'dreams'} end onClick={onClose}><><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M64 224h13.5c24.7 56.5 80.9 96 146.5 96s121.8-39.5 146.5-96H384c8.8 0 16-7.2 16-16v-96c0-8.8-7.2-16-16-16h-13.5C345.8 39.5 289.6 0 224 0S102.2 39.5 77.5 96H64c-8.8 0-16 7.2-16 16v96c0 8.8 7.2 16 16 16zm40-88c0-22.1 21.5-40 48-40h144c26.5 0 48 17.9 48 40v24c0 53-43 96-96 96h-48c-53 0-96-43-96-96v-24zm72 72l12-36 36-12-36-12-12-36-12 36-36 12 36 12 12 36zm151.6 113.4C297.7 340.7 262.2 352 224 352s-73.7-11.3-103.6-30.6C52.9 328.5 0 385 0 454.4v9.6c0 26.5 21.5 48 48 48h80v-64c0-17.7 14.3-32 32-32h128c17.7 0 32 14.3 32 32v64h80c26.5 0 48-21.5 48-48v-9.6c0-69.4-52.9-125.9-120.4-133zM272 448c-8.8 0-16 7.2-16 16s7.2 16 16 16 16-7.2 16-16-7.2-16-16-16zm-96 0c-8.8 0-16 7.2-16 16v48h32v-48c0-8.8-7.2-16-16-16z" /></svg></>{t('dreams_dictionary')}</NavLink>
                    <NavLink to={'books'} end onClick={onClose}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M448 360V24c0-13.3-10.7-24-24-24H96C43 0 0 43 0 96v320c0 53 43 96 96 96h328c13.3 0 24-10.7 24-24v-16c0-7.5-3.5-14.3-8.9-18.7-4.2-15.4-4.2-59.3 0-74.7 5.4-4.3 8.9-11.1 8.9-18.6zM128 134c0-3.3 2.7-6 6-6h212c3.3 0 6 2.7 6 6v20c0 3.3-2.7 6-6 6H134c-3.3 0-6-2.7-6-6v-20zm0 64c0-3.3 2.7-6 6-6h212c3.3 0 6 2.7 6 6v20c0 3.3-2.7 6-6 6H134c-3.3 0-6-2.7-6-6v-20zm253.4 250H96c-17.7 0-32-14.3-32-32 0-17.6 14.4-32 32-32h285.4c-1.9 17.1-1.9 46.9 0 64z" /></svg>{t('books')}</NavLink>
                    <div className='__border-bottom'></div>
                    <NavLink to={'about'} end onClick={onClose}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M448 384v64c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32v-64c0-17.7 14.3-32 32-32h384c17.7 0 32 14.3 32 32zM48.1 320h351.9c41.7 0 63.6-49.7 35.4-80.4L259.4 47.6c-19-20.7-51.8-20.7-70.8 0L12.7 239.6C-15.5 270.3 6.3 320 48.1 320z" /></svg>{t('about_us')}</NavLink>
                    <NavLink to={'contact'} end onClick={onClose}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M464 64H48C21.5 64 0 85.5 0 112v288c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zm0 48v40.8c-22.4 18.3-58.2 46.7-134.6 106.5-16.8 13.2-50.2 45.1-73.4 44.7-23.2 .4-56.6-31.5-73.4-44.7C106.2 199.5 70.4 171.1 48 152.8V112h416zM48 400V214.4c22.9 18.3 55.4 43.9 104.9 82.6 21.9 17.2 60.1 55.2 103.1 55 42.7 .2 80.5-37.2 103.1-54.9 49.5-38.8 82-64.4 104.9-82.7V400H48z" /></svg>{t('contact_us')}</NavLink>
                    <NavLink to={'help-and-support'} end onClick={onClose}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M202 0C122.2 0 70.5 32.7 29.9 91c-7.4 10.6-5.1 25.1 5.2 32.9l43.1 32.7c10.4 7.9 25.1 6 33.3-4.1 25-31.4 43.6-49.4 82.8-49.4 30.8 0 68.8 19.8 68.8 49.6 0 22.6-18.6 34.1-49 51.2-35.4 19.9-82.3 44.6-82.3 106.4V320c0 13.3 10.7 24 24 24h72.5c13.3 0 24-10.7 24-24v-5.8c0-42.9 125.3-44.6 125.3-160.6C377.5 66.3 286.9 0 202 0zM192 373.5c-38.2 0-69.3 31.1-69.3 69.3 0 38.2 31.1 69.3 69.3 69.3s69.3-31.1 69.3-69.3-31.1-69.3-69.3-69.3z"/></svg>{t('help_support')}</NavLink>
                    <div className='__border-bottom'></div>
                    {userDetails?.fullname ? <NavLink to={'settings'} onClick={onClose} end style={{ textDecoration: 'none', display: 'flex', gap: '10px', alignItems: 'center' }}><Avatar src={<img src={userDetails?.profile_pic} alt={userDetails?.fullname} />} size={25} /><span>{t('settings')}</span></NavLink> : <Button icon={<UserOutlined />} onClickHandler={() => setLogin(true)} width='100px' label={t('login')}/>}

                    <div>
                    <NavLink to={'privacy'} end onClick={onClose} style={{ fontSize: '1rem', marginBottom: '7px', marginTop: '10px' }}>{t('privacy_policy')}</NavLink>
                    <NavLink to={'cookie'} end onClick={onClose} style={{ fontSize: '1rem', marginBottom: '7px' }}>{t('cookies_policy')}</NavLink>
                    <NavLink to={'terms'} end onClick={onClose} style={{ fontSize: '1rem', marginBottom: '7px' }}>{t('terms_and_conditions')}</NavLink>
                    </div>

                </DrawerItems>
            </Drawer>
            <Login
                open={loginShow}
                onClose={() => setLogin(false)}
            />

        </HeaderOuter>
    );
};

export default Header;
