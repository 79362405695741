import React from "react";
import Spin from "antd/es/spin";
import { Props } from "./types";

const Loader: React.FC<Props> = (props: Props) => {
  const { fullscreen, size = 'default' } = props;

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Spin fullscreen={fullscreen} size={size} />
    </div>
  );
};

export default Loader;
