// Core
import React from 'react';
// Others
import AppRouter from 'routes';

// Styles
import { GlobalStyle } from 'assets/styles/globalStyles';
import './App.css'


export function App() {


  return (
      <>
      <GlobalStyle />
      <AppRouter />
      </>
  );
}

export default App;
