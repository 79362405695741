/* eslint-disable react-hooks/exhaustive-deps */
// Core
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

// Styles
import { CommonGlassContainer, H1Head } from "assets/styles/globalStyles";

import {
    BooksPage,
    BooksWrapper
} from "./styles";

// Components
import Loader from "components/Loader";

// Others
import { fetchData } from "utils/fetch";
import { restAPIs } from "utils/restAPIs";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// Components

import Footer from "components/Footer";
import { APP_ID } from "config/constants";

import { DownOutlined, PlusOutlined } from '@ant-design/icons';
import { Tree } from 'antd';
import type { TreeDataNode, TreeProps } from 'antd';

// Types


const BookTree: React.FC = () => {
    const { currentLang } = useSelector((store: any) => store.commonData);
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [booksList, setBooksList] = useState<any>([]);
    const [selectedPage, setSelectedPage] = useState<any>('')
    const { t } = useTranslation();

    const [bookIdParams] = useSearchParams();
    const [bookId, setBookId] = useState<any>(bookIdParams.get('bookId'))
    const [bookTitle, setBookTitle] = useState<any>(bookIdParams.get('bookTitle'))

    const titleRef = useRef<any>();

    const fetchBooks = async () => {
        setLoading(true);
        const res = await fetchData(restAPIs.bookTree(bookId, currentLang?.id, 0));
        setSelectedPage(res?.entity[0])
        setBooksList(res?.entity);
        setLoading(false);
        const tree = await buildTree(res?.entity);
        setBooksList(tree);
        
    };

    const buildTree = async (data: any[]) => {
        const tree = [];
        const map = new Map();

        // Create a map of subjectId to items
        data.forEach((item: { subjectId: any; }) => {
            map.set(item.subjectId, { ...item, children: [] });
        });

        // Iterate over the data to build the tree
        for (let i = 0; i < data.length; i++) {
            const item = data[i];
            const parent = map.get(item.parentId);
            if (parent) {
                parent.children.push(map.get(item.subjectId));
            } else {
                tree.push(map.get(item.subjectId));
            }

            // If the item has children, recursively fetch and add them
            if (item.hasChildren) {
                const children = await fetchData(restAPIs.bookTree(1, currentLang?.id, item.subjectId));
                map.get(item.subjectId).children = await buildTree(children.entity);
            }
        }

        return tree;
    };


    useEffect(() => {
        fetchBooks();
        window.scrollTo(0, 0);
    }, [currentLang]);

    const treeData: TreeDataNode[] = [
        {
            title: 'parent 1',
            key: '0-0',
            children: [
                {
                    title: 'parent 1-0',
                    key: '0-0-0',
                    children: [
                        {
                            title: 'leaf',
                            key: '0-0-0-0',
                        },
                        {
                            title: 'leaf',
                            key: '0-0-0-1',
                        },
                        {
                            title: 'leaf',
                            key: '0-0-0-2',
                        },
                    ],
                },
                {
                    title: 'parent 1-1',
                    key: '0-0-1',
                    children: [
                        {
                            title: 'leaf',
                            key: '0-0-1-0',
                        },
                    ],
                },
                {
                    title: 'parent 1-2',
                    key: '0-0-2',
                    children: [
                        {
                            title: 'leaf',
                            key: '0-0-2-0',
                        },
                        {
                            title: 'leaf',
                            key: '0-0-2-1',
                        },
                    ],
                },
            ],
        },
    ];

    const onSelect: TreeProps['onSelect'] = (selectedKeys, info) => {
        setSelectedPage(info?.selectedNodes[0])
        // gotoHeading();  
    };

    const gotoHeading = () => {
        titleRef.current?.scrollIntoView({ behavior: 'smooth' });
    }



    return (
        <>

            <BooksWrapper direction={(currentLang?.id) == 1 ? "rtl" : "ltr"}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-11">
                            <H1Head style={{ color: '#fff', marginBottom: '20px' }}>{bookTitle}</H1Head>
                            {isLoading ? <Loader /> :
                                <BooksPage direction={(currentLang?.id) == 1 ? "rtl" : "ltr"}>
                                    <CommonGlassContainer>
                                        <Tree
                                            showLine
                                            switcherIcon={<PlusOutlined />}
                                            onSelect={onSelect}
                                            treeData={booksList}
                                            className="___treeee"
                                            defaultSelectedKeys={["0-0"]}
                                        />
                                    </CommonGlassContainer>
                                    <CommonGlassContainer className="__page">
                                        <h1 ref={titleRef}>{selectedPage?.title}</h1>
                                        <p  dangerouslySetInnerHTML={{ __html: selectedPage?.description }}></p>
                                    </CommonGlassContainer>
                                </BooksPage>}
                        </div>
                    </div>
                </div>

                <Footer />

            </BooksWrapper>
        </>
    );
};

export default BookTree;

