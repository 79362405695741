import { APP_ID, BASE_URL, USER_HOST } from "config/constants";


export const restAPIs = {

  login: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_login.php`
  }),
  signUp: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_userRegistration.php`
  }),
  changePassword: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_user_changePassword`
  }),
  changeProfPic: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_user_changeProfilePic`
  }),
  changeLanguage: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_user_changeLanguage`
  }),
  otpVerify: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_otpverify`
  }),
  forgotPassword: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_forgotPassword`
  }),
  resetPassword: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_user_resetPassword`
  }),
  changeName: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_user_changeUsername.php`
  }),
  getWallet: (body: any) => ({
    method: 'get',
    endpoint: `${USER_HOST}/wallet.php`
  }),
  getCountryList: (body: any) => ({
    method: 'get',
    endpoint: `${USER_HOST}/countryinfos.php`
  }),
  contactUs: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_contactus.php`
  }),
  changeEmail: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_user_changeEmail`
  }),
  changeEmailOTP: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_sendOtp`
  }),
  deleteAccount: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_user_deleteUser`
  }),
  etisalatEncryption: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/etisalat_encrypt.php`
  }),

  unSubscribe: (body:any) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/unsubscribe.php`,
  }),


  getEVApps: (language: any, appId: any = null) => ({
    method: 'get',
    endpoint: `${USER_HOST}/json_applications.php?language=${language}${appId ? '&appId=' + appId : ''}`
  }),

  getAppInfo: (appId: any, language: any) => ({
    method: 'get',
    endpoint: `https://www.electronicvillage.org/json_appInfo.php?appId=${appId}&language=${language}`
  }),
  getFounderInfo: (language: any) => ({
    method: 'get',
    endpoint: `https://www.electronicvillage.org/json_founder.php?language=${language}`
  }),
  getEVPages: (language: any, pageId: any) => ({
    method: 'get',
    endpoint: `https://www.electronicvillage.org/json_evPages.php?language=${language}&pageId=${pageId}`
  }),
  translateData: (client: any, sl: any, tl: any, dt: any, q: any) => ({
    method: 'get',
    endpoint: `https://translate.googleapis.com/translate_a/single?client=${client}&sl=${sl}&tl=${tl}&dt=${dt}&q=${q}`
  }),
  getSubscriptions: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_subscriptions.php`
  }),
  subscriptionForm: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_subscription_form.php`
  }),
  encryption: (data: any) => ({
    method: 'post',
    endpoint: `${USER_HOST}/etisalat_encrypt.php?data=${data}`
  }),

  helpAndSupport: (language:any) => ({
    method: 'get',
    endpoint: `${USER_HOST}/json_appHelpSupport.php?appId=${APP_ID}&language=${language}`
  }),

  listDreams: (language: any, dreamId: any, charId: any, searchText: any, searchOption:any) => ({
    method: 'get',
    endpoint: `${BASE_URL}/json_dreams.php?searchOption=${searchOption}&language=${language}${dreamId ? '&dreamId=' + dreamId : ''}${charId ? '&charId=' + charId : ''} ${searchText ? '&searchText=' + searchText : ''}`
  }),

  listLetters: (language: any) => ({
    method: 'get',
    endpoint: `${BASE_URL}/json_chars.php?language=${language}`
  }),

  chatGPT: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/chatgpt`
  }),

  listBooks: (language: any) => ({
    method: 'get',
    endpoint: `${BASE_URL}/json_books.php?language=${language}`
  }),

  bookTree: (bookId:any, language: any, parentId:any) => ({
    method: 'get',
    endpoint: `${BASE_URL}/json_booktree.php?language=${language}&parentId=${parentId}&bookId=${bookId}`
  }),
  
};
