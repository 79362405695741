import React from "react";

// Style
import { StyledButton } from "./styles";
import { Props } from "./types";
import { Label } from "assets/styles/globalStyles";
import { colors } from "assets/styles/colors";

// Types
import { FontSizes } from "utils/enums";

// Others
import { SizeType } from "antd/es/config-provider/SizeContext";
import { Tag } from "antd";

import { LoadingOutlined } from '@ant-design/icons';
import Loader from "components/Loader";

const CustomButton: React.FC<Props> = (props: Props) => {
  const {
    size,
    marginTop,
    label,
    onClickHandler,
    marginBottom,
    color = colors.primary,
    type = "primary",
    width,
    loading,
    disabled,
    icon,
    tagCount,
    backgroundColor
  } = props;

  return (
    <StyledButton
      {...{ marginBottom, marginTop, width, backgroundColor}}
      onClick={onClickHandler}
      type={type}
      loading={loading}
      disabled={disabled || loading}
      color={color}
      icon={icon}
      size={size as SizeType}
    >
      {/* {loading ? <Loader size="small"/> :''} */}
      {!loading && label && (
        <Label fontSize={FontSizes.button}>
          {label} {!!tagCount && <Tag color={colors.error}>{tagCount}</Tag>}
        </Label>
      )}
    </StyledButton>
  );
};

export default CustomButton;
