import { colors } from "assets/styles/colors"

export enum FontSizes { 
    default = 1,
    input = 0.8,
    table = 0.7,
    floatingLabel = 0.7,
    checkbox = 0.7,
    button = 0.7,
    tableAction = 0.7,
    small = 0.7,
    sidebar = 0.8,
    sidebarIcon = 1,
    error = 0.6,
    tag = 0.6,
    h1 = 1.8,
    priceTag = 2.0,
    h2 = 1.2,
    h3 = 1,
    h4 = 0.9,
    h6 = 0.8,
}

export interface OptionType {
    value: string;
    label: string;
  }

export enum FontWeight { 
    light = 300,
    default = 400,
    medium = 500,
    bold = 600,
    bolder = 700,
}

export const ColorMap =  {
    OnboardingComplete: colors.success2,
    Invited: colors.orange,
    EmailVerified: colors.orange,
}

export enum ActionTypes {
    USER_DETAILS = 'userDetails',
    CHANGE_LANGUAGE = 'currentLang'
}

