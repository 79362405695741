/* eslint-disable react-hooks/exhaustive-deps */
// Core
import React, { ChangeEvent, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

// Styles
import { CommonGlassContainer, H1Head } from "assets/styles/globalStyles";

import {
    BookItem,
    BooksListWrap,
    BooksWrapper
} from "./styles";

// Components
import Loader from "components/Loader";

// Others
import { fetchData } from "utils/fetch";
import { restAPIs } from "utils/restAPIs";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// Components

import Footer from "components/Footer";
import { APP_ID } from "config/constants";

// Types


const Books: React.FC = () => {
    const { currentLang } = useSelector((store: any) => store.commonData);
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [booksList, setBooksList] = useState<any>([]);
    const { t } = useTranslation();




    const fetchBooks = async () => {
        setLoading(true)
        const res = await fetchData(restAPIs.listBooks(currentLang?.id));
        console.log("res letters", res);
        setBooksList(res?.entity);
        setLoading(false)
    };


    useEffect(() => {
        fetchBooks();
        window.scrollTo(0, 0);
    }, [currentLang]);


    return (
        <>


            <BooksWrapper direction={(currentLang?.id) == 1 ? "rtl" : "ltr"}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-11">
                            <H1Head style={{ color: '#fff', marginBottom: '20px' }}>{t('books')}</H1Head>
                            <BooksListWrap>
                                {booksList && booksList.map((item: any, idx: any) => {
                                    return (
                                        <CommonGlassContainer>
                                            <BookItem onClick={() => navigate('/book?bookId=' + item?.bookId + '&bookTitle=' + item?.bookName)}>
                                                <article>
                                                    <div className="cover-back"></div>
                                                    <div className="pages">
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                    </div>
                                                    <div className="cover" style={{backgroundImage:`url(${item?.coverImage})`}}>
                                                    <span className="title">{item?.bookName}</span>
                                                    </div>
                                                    <div className="spine">
                                                    </div>
                                                    <div className="spine-shadow"></div>
                                                </article>

                                                <h1>{item?.bookName}</h1>
                                                <p>{item?.description}</p>
                                            </BookItem>
                                        </CommonGlassContainer>

                                    )
                                })}
                                <div></div>
                                <div></div>

                            </BooksListWrap>
                        </div>
                    </div>
                </div>

                <Footer />

            </BooksWrapper>
        </>
    );
};

export default Books;

