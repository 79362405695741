import { colors } from "assets/styles/colors";
import { Grid } from "assets/styles/globalStyles";
import { FontSizes } from "utils/enums";
import styled from "styled-components";

export const BooksWrapper = styled.div<any>`
    padding-top:145px;
    position:relative;
    min-height:100vh;
    width:100%;
    direction:${(props) => props.direction};
`

export const BooksPage = styled.div<any>`
    grid-gap: 40px;
    display: grid;
    grid-template-columns: 280px 1fr;
    margin: 20px 0;
    width: 100%;
    @media (max-width: 556px) {
        grid-template-columns: 1fr;
    }

    ${(props) => props.direction === 'rtl' ?
        `
    .___treeee{
        font-size:25px;
        line-height:2.5;
        font-family: "Lateef", serif !important;
        
    }`
        :
        ` .___treeee{
            font-size:18px;
            line-height:2.5;
            font-family: "Montserrat", sans-serif;
}`
    }
    
    .___treeee{
        background:transparent;
        color:inherit;
        .ant-tree-node-content-wrapper.ant-tree-node-selected{
            background: #ffffff61;
            padding: 3px 10px;
        }
    }

    .__page{

        ${(props) => props.direction === 'rtl' ?
        `h1{
            font-size:46px;
        }
        p{
            font-size:30px;
        }
        ` :
        `h1{
            font-size:30px;
        }
        p{
            font-size:18px;
        }
        `
    }


        h1{
            text-align:center;
            margin-bottom:30px;
            margin-bottom:20px;

        }
        p{
            
            line-height: 2;
            text-align: justify;
            font-style: normal;
            letter-spacing: .05em;
        }
    }
`
