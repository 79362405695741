import React, { ChangeEvent, useState } from "react";
import Input from "antd/es/input";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

// Style
import { Container, ErrorLabel, EyeBox } from "./styles";
import { Props } from "./types";
import { Label } from "assets/styles/globalStyles";
import { FontSizes, FontWeight } from "utils/enums";
import { colors } from "assets/styles/colors";

const InputField: React.FC<Props> = (props: Props) => {
  const {
    type,
    placeholder,
    onChange,
    value = "",
    label,
    error,
    onKeyDown,
    marginTop,
    marginBottom,
    isMandatory,
    noLabel,
    noError,
    disabled,
    viewMode,
    color
  } = props;

  const [showEye, setShowEye] = useState(false);

  const handleEyeClick = () => setShowEye((prev) => !prev);

  const onChangeHandle = (e: ChangeEvent<HTMLInputElement>) =>
    onChange?.(e.target.value);

  return (
    <Container {...{ marginBottom, marginTop, error: !!error }}>
      {label && !noLabel && (
        <Label
          marginBottom={viewMode ? 0 : 5}
          fontSize={FontSizes.floatingLabel}
          color={color || colors.primaryText}
          fontWeight={FontWeight.default}
        >
          {label} {isMandatory && !viewMode && <span>*</span>}
        </Label>
      )}
      
        <>
          <Input
            disabled={disabled}
            autoComplete="off"
            status={error ? "error" : ""}
            value={value as string}
            placeholder={placeholder || label}
            onChange={onChangeHandle}
            type={showEye ? "text" : type || "text"}
            onKeyDown={onKeyDown}
          />
          {type === "password" && (
            <EyeBox onClick={handleEyeClick}>
              {showEye ? <EyeOutlined /> : <EyeInvisibleOutlined />}
            </EyeBox>
          )}
          {!noError && !!error && (
            <ErrorLabel
              fontSize={FontSizes.error}
              color={colors.error}
              fontWeight={FontWeight.light}
            >
              {error}
            </ErrorLabel>
          )}
        </>
      
    </Container>
  );
};

export default InputField;
