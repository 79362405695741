import { colors } from "assets/styles/colors";
import { Grid } from "assets/styles/globalStyles";
import { FontSizes } from "utils/enums";
import styled from "styled-components";

export const BooksWrapper = styled.div<any>`
    padding-top:145px;
    position:relative;
    min-height:100vh;
    width:100%;    
    direction:${(props) => props.direction};
`

export const BooksListWrap = styled.div`
grid-gap: 25px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    margin: 20px 0;
    width: 100%;
`

export const BookItem = styled.div`
text-align:center;
cursor:pointer;
h1{
    margin:0;
    font-size:1.6rem;
    margin-top:15px;
}

  article {
    display: block;
    width: 230px;
    height: 310px;
    margin: auto;
    position: relative;
    z-index: 0;
    transform: scale(0.9) rotateX(10deg) rotateY(-30deg) rotateZ(0.2deg) translateX(-15px);
  }
  
  .cover-back, .pages, .cover {
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 0 6px 6px 0;
    box-shadow: 1px 0px 1px rgba(0,0,0,0.9);
    
    position: absolute;
  }
  
  .cover-back {
    width: 108.7%;
    top: 0.4%;
    left: -4.93%;
      
    background: #f1f1ef;
  }
  
  .pages {
    width: 103.4%;
    height: 96%;
    
    top: 1.35%;
    left: -0.3%;
      
    background: #e0ddd7 linear-gradient(to bottom, transparent 2px, #999 2px, transparent 4px);
      
      span {
        content: "";
        display: block;
        width: 103.8%;
        height: 100%;
        top: 1px;
        left: -3.4%;
        position: absolute;
        background: #e0ddd7;
        box-shadow: 1px 0px 1px rgba(0,0,0,0.9);
        
        &:nth-child(2) {
          top: 4px;
          width: 104.6%;
        }
        
        &:nth-child(3) {
          top: 6px;
          width: 105%;
        }
        
        &:nth-child(4) {
          top: 8px;
          width: 105.6%;
        }
        
        &:nth-child(5) {
          top: 10px;
          width: 106.2%;
        }
      }
  }
  
  .cover {
    top: 3%;
    left: 0.8%;
    
    width: 109%;
      
    background: #f3f3f0 url(https://upload.wikimedia.org/wikipedia/commons/thumb/a/a2/Moai_Rano_raraku.jpg/800px-Moai_Rano_raraku.jpg) center no-repeat;
    background-size: cover;
    
    .title {
        display: inline-block;
        color: white;
        position: absolute;
        bottom: 8%;
        left: 16%;
        width: 70%;
        font-size: 1.62rem;
        font-family: sans-serif;
        text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.8);
        text-align: center;
        line-height: 1.3;
    }
  }
  
  .spine {
    display: block;
    height: 100%;
    width: 10%;
    background: #000;
    
    position: absolute;
    top: 1.9%;
    left: -6.6%;
    
    transform-style: preserve-3d;
    transform: rotateX(0deg) rotateY(50deg) rotateZ(0deg) skew(0.19deg, 22deg);
    
     span {
      display:inline-block;
      color: white;
      position: absolute;
      bottom: 15%;
      left: -30%;
      width: 200%;
      height: 5%;
      transform: rotate(-94deg);
      font-size: 16px;
      font-family: sans-serif;
      text-align: left;
    }
  }
  
  .spine-shadow {
    display: block;
    background: linear-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0.5));
    width: 1.6%;
    height: 100%;
    position: absolute;
    top: 3%;
    left: 3.8%;
  }
  
  // // // // 
  
  .cover-back {
    z-index: 1;
  }
  .pages {
    z-index: 2;
  }
  .cover {
    z-index: 5; 
  }
  .spine {
    z-index: 6;  
  }
  .spine-shadow {
    z-index: 7; 
  }
  
  // // // //
  
  @media (max-width:700px){
    :root {
      --titlesize: 3rem; 
    }
  
    article {
      width: 230px;
      height: 380px;
    }
    
    .spine {
      span {
        display: none;
      }
    }
  }

`
