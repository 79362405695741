// Core
import { combineReducers } from 'redux';

/// Others
import commonData from './userDetails.reducer';

const rootReducer: any = combineReducers({
  commonData
});
export default rootReducer;
