import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import {
  BackgroundWrapper, ContentWrapper, GradientWrap, Stars
} from "./styles";

import Loader from 'components/Loader';
import Header from 'components/Header';
import Books from 'pages/Books';
import BookTree from 'pages/Books/BookTree';

const HomePage = React.lazy(() => import('pages/Homepage'));
const About = React.lazy(() => import('../pages/About'));
const Founder = React.lazy(() => import('../pages/About/Founder'));
const Contact = React.lazy(() => import('../pages/Contact'));
const Policy = React.lazy(() => import('../pages/StaticPages/Policy'));
const Subscription = React.lazy(() => import('../pages/Subscription'));
const Dreams = React.lazy(() => import('../pages/Dreams'));
const ChatGPT = React.lazy(() => import('../pages/ChatGPT'));
const Settings = React.lazy(() => import('../pages/Settings'));
const HelpAndSupport = React.lazy(() => import('../pages/HelpAndSupport'));

const AppDetail = React.lazy(() => import('../pages/About/AppDetail'));

// const SubscribePayment = React.lazy(() => import('../components/pages/SubscribePayment'));
// const Etisalat = React.lazy(() => import('../components/pages/Etisalat'));
// const HelpAndSupport = React.lazy(() => import('../components/pages/HelpAndSupport'));
// const Thanks = React.lazy(() => import('../components/pages/Thanks'));

const AppRouter = () => {
  return (
    <>
      <BrowserRouter>
        <div className='__dark'>
        <BackgroundWrapper>
                <GradientWrap />
                <Stars>
                    <div id="stars"></div>
                    <div id="stars2"></div>
                    <div id="stars3"></div>
                </Stars>
          <Header />
          <ContentWrapper>
            <Suspense fallback={<Loader/>}>
              <Routes>
                <Route path="/home" element={<HomePage />} />
                <Route path="*" element={<HomePage />} />
                <Route path="/about" element={<About />} />
                <Route path="/about/app/:appId" element={<AppDetail />} />
                <Route path="/founder" element={<Founder />} />
                <Route path="/privacy" element={<Policy id='59' />} />
                <Route path="/cookie" element={<Policy id='46' />} />
                <Route path="/terms" element={<Policy id='47' />} />
                <Route path="/subscribe" element={<Subscription />} />
                <Route path="/dreams" element={<Dreams />} />
                <Route path="/books" element={<Books />} />
                <Route path="/book" element={<BookTree />} />
                <Route path="/chatgpt" element={<ChatGPT />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/settings" element={<Settings />} />

                <Route path='/help-and-support' element={<HelpAndSupport />} />
                {/* 


                

                <Route path="/privacy" element={<Policy id='59' />} />
                <Route path="/cookie" element={<Policy id='46' />} />
                <Route path="/terms" element={<Policy id='47' />} />
                <Route path='/help-and-support' element={<HelpAndSupport />} />

                <Route path="/settings" element={<Subscription />} />

                <Route path="/subscribe/:appId" element={<Subscription />} />
                <Route path="/subscription-payment/:subId/:appId" element={<SubscribePayment />} />
                <Route path="/etisalat/:subId" element={<Etisalat />} />

                <Route path="/thanks" element={<Thanks />} /> */}
              </Routes>
            </Suspense>
            
          </ContentWrapper>
          
          </BackgroundWrapper>
        </div>
      </BrowserRouter>
    </>
  );
};

export default AppRouter;
