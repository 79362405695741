// Core
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Styles
import './styles.css';
import { Button, Modal, message } from 'antd';
import { DontAccount, ForgotPassword, ForgotTemplate, LeftWrap, LoginTemplate, LoginWrapper, OTPTemplate, RightWrap, SignupTemplate, SocialButton } from './styles';
import InputField from 'components/InputField';
import CustomButton from 'components/Button';
import { ForgotParameters, ResetParameters, SignInParameters, SignUpParameters } from './types';
import { validateEmail } from 'utils/helpers';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { APP_ID } from 'config/constants';
import { useDispatch, useSelector } from 'react-redux';
import { IResolveParams, LoginSocialFacebook, LoginSocialGoogle } from 'reactjs-social-login';
import { FACEBOOK_ID, GOOGLE_ID } from 'config/constants';
import FacebookLogo from 'assets/images/facebookLogo.png'
import GoogleLogo from 'assets/images/googleLogo.png'
import OTPInput from 'react-otp-input';

import Logo from 'assets/images/logo.png'
import DrLogo from 'assets/images/dreamslogo.png'

const REDIRECT_URI = window.location.href;

const Login = (props: any) => {
    const { onClose, open, title, description } = props;
    const { currentLang, userDetails } = useSelector((store: any) => store.commonData);
    const [messageApi, contextHolder] = message.useMessage();
    const [profile, setProfile] = useState<any>(null)
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [form, setForm] = useState<SignInParameters>({
        email: "",
        password: "",
    });
    const [loading, setLoading] = useState(false);
    const { email, password } = form;
    const [errorsLogin, setErrorsLogin] = useState<SignInParameters>({
        email: "",
        password: "",
    });
    const [formSignup, setFormSignup] = useState<SignUpParameters>({
        nameSignup: "",
        emailSignup: "",
        passwordSignup: "",
    });
    const [loadingSignup, setLoadingSignup] = useState(false);
    const { nameSignup, emailSignup, passwordSignup } = formSignup;
    const [errorsSignup, setErrorsSignup] = useState<SignUpParameters>({
        nameSignup: "",
        emailSignup: "",
        passwordSignup: "",
    });
    const [response, setSignupResp] = useState<any>({});
    const [loginEye, setLoginEye] = useState(true);
    const [islogin, setIsLogin] = useState(true);

    const [openOtp, setOpenOtp] = useState(false);
    const [type, setOTPType] = useState('')
    const [otpEmail, setOTPEmail] = useState('')
    const [otp, setDataOTP] = useState('');
    const [otpTemp, setDataOTPTemp] = useState('');
    const [isLoadingOTP, setIsLoadingOTP] = useState(false);

    const [openForget, setOpenForgot] = useState(false)
    const [formForgot, setFormForgot] = useState<ForgotParameters>({
        emailForgot: "",
    });
    const [loadingForgot, setLoadingForgot] = useState(false);
    const { emailForgot } = formForgot;
    const [errorsForgot, setErrorsForgot] = useState<ForgotParameters>({
        emailForgot: "",
    });



    const [openReset, setResetOpen] = useState(false)
    const [formReset, setFormReset] = useState<ResetParameters>({
        newPassword: "",
        confirmPassword: "string",
    });
    const [loadingReset, setLoadingReset] = useState(false);
    const { newPassword, confirmPassword } = formReset;
    const [errorsReset, setErrorsReset] = useState<ResetParameters>({
        newPassword: "",
        confirmPassword: "string",
    });

    const validateFormReset = (): boolean => {
        const newError = {
            newPassword: newPassword ? '' : 'Password is required',
            confirmPassword: newPassword ? '' : 'Password is required' && newPassword === confirmPassword ? '' : 'Passwords must be same',
        }
        setErrorsReset(newError)
        return !Object.values(newError).some(item => !!item);
    };


    const validateFormForgot = (): boolean => {
        const newError = {
            emailForgot: validateEmail(emailForgot) ? '' : 'Please enter a valid email address',
        }
        setErrorsForgot(newError)
        return !Object.values(newError).some(item => !!item);
    };


    const validateForm = (): boolean => {
        const newError = {
            email: validateEmail(email) ? '' : 'Please enter a valid email address',
            password: password ? '' : 'Password is required',
        }
        setErrorsLogin(newError)
        return !Object.values(newError).some(item => !!item);
    };

    const validateFormSignup = (): boolean => {
        const newError = {
            emailSignup: validateEmail(emailSignup) ? '' : 'Please enter a valid email address',
            passwordSignup: passwordSignup ? '' : 'Password is required',
            nameSignup: nameSignup ? '' : 'Name is required',
        }
        setErrorsSignup(newError)
        return !Object.values(newError).some(item => !!item);
    };

    const onchangeHandlerSignup = (key: string) => (value: string) => {
        setFormSignup({
            ...formSignup,
            [key]: value,
        });
    };

    const onchangeHandler = (key: string) => (value: string) => {
        setForm({
            ...form,
            [key]: value,
        });
    };

    const onchangeHandlerForgot = (key: string) => (value: string) => {
        setFormForgot({
            ...formForgot,
            [key]: value,
        });
    };


    const onchangeHandlerReset = (key: string) => (value: string) => {
        setFormReset({
            ...formReset,
            [key]: value,
        });
    };


    const handleSignIn = async () => {
        if (!validateForm()) return null;
        setLoading(true);
        const formData = new FormData();
        formData.append('email', email.toLowerCase())
        formData.append('password', btoa(unescape(encodeURIComponent(password))))
        formData.append('appid', APP_ID)
        setOTPEmail(email.toLowerCase())
        const res = await fetchData(restAPIs.login(formData));
        if (res.statusCode === 200) {
            dispatch({
                type: 'userDetails',
                value: res.userinfo
            });
            localStorage.setItem('userDetails', JSON.stringify(res.userinfo));
            localStorage.setItem('sessiontoken', res.userinfo.sessionToken);
            onClose();
        }
        else if (res.statusCode === 100) {
            setOpenOtp(true);
            setOTPType('login')
        }

        else {
            messageApi.open({
                type: 'error',
                content: res?.errormessage,
            });
        }


        setLoading(false);
    };

    const handleClose = () => {
        onClose();
    }

    const handleSocialLogin = async (user: any, auth: any) => {
        console.log("user", user);

        const formData = new FormData();
        formData.append('fullname', user.name)
        formData.append('email', user.email)
        formData.append('auth', auth.toString())
        formData.append('language', currentLang?.id)
        formData.append('appid', APP_ID)
        // setLoading(true);
        const res = await fetchData(restAPIs.signUp(formData));
        if (res.statusCode === 200) {
            dispatch({
                type: 'userDetails',
                value: res.user
            });
            localStorage.setItem('userDetails', JSON.stringify(res.user));
            localStorage.setItem('sessiontoken', res.user.sessionToken);
            handleClose();
        } else {

            messageApi.open({
                type: 'error',
                content: res?.errormessage,
            });
        }
    }

    const onLoginStart = useCallback(() => {
        console.log("started, login");

        // alert('login start')
    }, []);

    const onLogoutSuccess = useCallback(() => {
        console.log("log outed");
        // alert('logout success')
    }, []);


    // signup

    const handleSignUp = async () => {
        if (!validateFormSignup()) return null;
        setLoadingSignup(true);
        const formData = new FormData();
        formData.append('fullname', nameSignup)
        formData.append('email', emailSignup)
        formData.append('password', btoa(unescape(encodeURIComponent(passwordSignup))))
        formData.append('auth', '0')
        formData.append('language', currentLang?.id)
        formData.append('appid', APP_ID)

        const res = await fetchData(restAPIs.signUp(formData));
        if (res.statusCode === 200) {

            setSignupResp(res.user)
            setOpenOtp(true)
            setOTPType('signup')
        }

        if (res.statusCode === 500) {
            messageApi.open({
                type: 'error',
                content: res?.errormessage,
            });
        }
        setLoadingSignup(false);
    };

    const handleOnChangeOTP = (value: any) => {
        setDataOTP(value)
    }

    const handleCancelOTP = () => {
        setOpenOtp(false)
        setDataOTP('')
    }

    const handleSaveOTP = async () => {
        setIsLoadingOTP(true);
        const formData = new FormData();
        formData.append('email', otpEmail)
        formData.append('otp', otp)
        formData.append('appId', APP_ID)
        setDataOTPTemp(otp)
        const res = await fetchData(restAPIs.otpVerify(formData));
        if (res.statusCode === 200) {
            messageApi.open({
                type: 'success',
                content: res?.errormessage,
            });

            if (type == 'signup') {
                let full = { ...response, ...res.user }
                dispatch({
                    type: 'userDetails',
                    value: full
                });
                localStorage.setItem('userDetails', JSON.stringify(full));
                localStorage.setItem('sessiontoken', response.sessionToken);
                handleClose();
            }

            if (type == 'login') {
                dispatch({
                    type: 'userDetails',
                    value: res.user
                });
                localStorage.setItem('userDetails', JSON.stringify(res.user));
                localStorage.setItem('sessiontoken', res.user.sessionToken);
                handleClose();
            }
            if (type == 'forgot') {
                setResetOpen(true);
            }
            setDataOTP('');
            setOpenOtp(false);
        } else {
            messageApi.open({
                type: 'error',
                content: res?.errormessage,
            });
        }
        setIsLoadingOTP(false);
    };


    // forgot



    const handleSaveForgotEmail = async () => {
        if (!validateFormForgot()) return null;
        setLoadingForgot(true);
        const formData = new FormData();
        formData.append('email', emailForgot)
        formData.append('appId', APP_ID)
        setOTPEmail(emailForgot.toLowerCase())
        const res = await fetchData(restAPIs.forgotPassword(formData));
        if (res.statusCode === 200) {
            setOpenOtp(true);
            setOTPType('forgot')

        } else {
            messageApi.open({
                type: 'error',
                content: res?.errormessage,
            });
        }
        setLoadingForgot(false);
    };

    const handleForgotBack = () => {
        setOpenForgot(false);
        setIsLogin(true)
    }

    const handleSaveResetPassword = async () => {
        if (!validateFormReset()) return null;
        setLoadingReset(true);
        const formData = new FormData();
        formData.append('email', otpEmail)
        formData.append('otp', otpTemp)
        formData.append('newPassword', newPassword)
        formData.append('appId', APP_ID)

        const res = await fetchData(restAPIs.resetPassword(formData));
        if (res.statusCode === 200) {
            setOpenForgot(false);
            setOTPEmail('')
            setDataOTP('')
            setIsLogin(true)

        } else {
            messageApi.open({
                type: 'error',
                content: res?.errormessage,
            });
        }
        setLoadingReset(false);
    }


    const handleResetBack = () => {
        setResetOpen(false)
        setIsLogin(true)
    }

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            handleSignIn();
        }
    };


    return (
        <>
            <Modal
                open={open}
                onCancel={handleClose}
                className='__common_model_class'
                footer={null}
                keyboard={false}
                closable={true}
                maskClosable={false}
            >
                {contextHolder}
                <LoginWrapper style={{ gridTemplateColumns: (!openOtp && !openForget && !openReset) ? '1fr 1fr' : '1fr' }}>
                    {!openOtp && !openForget && !openReset && <LeftWrap>
                        <h1>Analyse your Dreams<br /> Wake Up to Brighter <br />Days</h1>
                        <h4><img src={DrLogo} alt="" style={{ width: '100px' }} /></h4>
                    </LeftWrap>}
                    <RightWrap direction={(currentLang?.id) == 1 ? "rtl" : "ltr"}>
                        <svg className='__svg_cls' width="899" height="1117" viewBox="0 0 899 1117" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.4" filter="url(#filter0_f_324_1597)">
                                <path d="M468.153 758.793L554.672 863.669L356.779 957.543L468.153 758.793L312.333 569.912C401.564 592.879 553.578 602.066 447.795 455.072C315.565 271.33 157.534 268.535 275.536 192.806C369.939 132.218 497.69 158.161 549.764 178.708L417.105 357.582L664.397 408.586L468.153 758.793Z" fill="url(#paint0_linear_324_1597)" />
                            </g>
                            <defs>
                                <filter id="filter0_f_324_1597" x="0.0696259" y="-78.8264" width="898.327" height="1270.37" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                    <feGaussianBlur stdDeviation="117" result="effect1_foregroundBlur_324_1597" />
                                </filter>
                                <linearGradient id="paint0_linear_324_1597" x1="261.573" y1="133.739" x2="752.819" y2="297.906" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#E59BFF" />
                                    <stop offset="0.49" stop-color="#6D33ED" />
                                    <stop offset="1" stop-color="#C48EEC" />
                                </linearGradient>
                            </defs>
                        </svg>
                        {
                            !openOtp && !openForget && !openReset &&
                            <>
                                {
                                    islogin ?
                                        <LoginTemplate>
                                            <h2>Log in to your account</h2>
                                            <p style={{ marginBottom: '40px' }}>Welcome back! Please enter your details.</p>
                                            <InputField
                                                error={errorsLogin.email}
                                                onChange={onchangeHandler("email")}
                                                value={email}
                                                marginBottom={20}
                                                placeholder={t('email')}
                                                label={t('email')}
                                                color="#222"
                                                onKeyDown={handleKeyDown}
                                            />
                                            <InputField
                                                error={errorsLogin.password}
                                                onChange={onchangeHandler("password")}
                                                value={password}
                                                placeholder={t('password')}
                                                label={t('password')}
                                                type="password"
                                                color="#222"
                                                onKeyDown={handleKeyDown}
                                            />
                                            <ForgotPassword><a onClick={() => setOpenForgot(true)}>Forgot Password?</a></ForgotPassword>
                                            <CustomButton
                                                loading={loading}
                                                onClickHandler={handleSignIn}
                                                marginTop={12}
                                                width="100%"
                                                label={t('login')}
                                            />
                                            <DontAccount>OR</DontAccount>
                                            <SocialButton >

                                                <LoginSocialFacebook

                                                    fieldsProfile={
                                                        'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
                                                    }
                                                    redirect_uri={REDIRECT_URI}
                                                    appId={FACEBOOK_ID}
                                                    onLoginStart={onLoginStart}
                                                    onResolve={({ data }) => {
                                                        setProfile(data)
                                                        handleSocialLogin(data, 1)
                                                    }}
                                                    onReject={(err) => {
                                                        console.log(err)
                                                    }}

                                                >
                                                    <img src={FacebookLogo} alt="Facebook" /> {t('sign_in_with_facebook')}
                                                </LoginSocialFacebook>
                                            </SocialButton>
                                            <SocialButton>

                                                <LoginSocialGoogle
                                                    fetch_basic_profile
                                                    redirect_uri={REDIRECT_URI}
                                                    scope="openid profile email"
                                                    discoveryDocs="claims_supported"
                                                    access_type="offline"
                                                    client_id={GOOGLE_ID}
                                                    onLoginStart={onLoginStart}
                                                    onResolve={({ provider, data }) => {
                                                        setProfile(data)
                                                        handleSocialLogin(data, 2)
                                                    }}
                                                    onReject={(err) => {
                                                        console.log(err)
                                                    }}
                                                >
                                                    <img src={GoogleLogo} alt="Google" /> {t('sign_in_with_google')}
                                                </LoginSocialGoogle>
                                            </SocialButton>

                                            <DontAccount>Don’t have an account? <a onClick={() => setIsLogin(false)}>{t('sign_up')}</a></DontAccount>
                                            <p style={{ color: '#adaaaa', marginTop: '40px' }}>{t('ev_account_can_be')}</p>
                                        </LoginTemplate>
                                        :
                                        <SignupTemplate>
                                            <h2 style={{ marginBottom: '40px' }}>Create an account</h2>

                                            <InputField
                                                error={errorsSignup.nameSignup}
                                                onChange={onchangeHandlerSignup("nameSignup")}
                                                value={nameSignup}
                                                marginBottom={20}
                                                placeholder={t('name')}
                                                label={t('name')}
                                                color="#222"
                                            />

                                            <InputField
                                                error={errorsSignup.emailSignup}
                                                onChange={onchangeHandlerSignup("emailSignup")}
                                                value={emailSignup}
                                                marginBottom={20}
                                                placeholder={t('email')}
                                                label={t('email')}
                                                color="#222"
                                            />
                                            <InputField
                                                error={errorsSignup.passwordSignup}
                                                onChange={onchangeHandlerSignup("passwordSignup")}
                                                value={passwordSignup}
                                                placeholder={t('password')}
                                                label={t('password')}
                                                type="password"
                                                color="#222"

                                            />
                                            <CustomButton
                                                loading={loadingSignup}
                                                onClickHandler={handleSignUp}
                                                marginTop={12}
                                                width="100%"
                                                label="Get started"
                                            />

                                            <DontAccount>OR</DontAccount>
                                            <SocialButton >
                                                <LoginSocialFacebook

                                                    fieldsProfile={
                                                        'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
                                                    }
                                                    redirect_uri={REDIRECT_URI}
                                                    appId={FACEBOOK_ID}
                                                    onLoginStart={onLoginStart}
                                                    onResolve={({ data }) => {
                                                        setProfile(data)
                                                        handleSocialLogin(data, 1)
                                                    }}
                                                    onReject={(err) => {
                                                        console.log(err)
                                                    }}

                                                >
                                                    <img src={FacebookLogo} alt="Facebook" /> {t('sign_in_with_facebook')}
                                                </LoginSocialFacebook>
                                            </SocialButton>
                                            <SocialButton>
                                                <LoginSocialGoogle
                                                    fetch_basic_profile
                                                    redirect_uri={REDIRECT_URI}
                                                    scope="openid profile email"
                                                    discoveryDocs="claims_supported"
                                                    access_type="offline"
                                                    client_id={GOOGLE_ID}
                                                    onLoginStart={onLoginStart}
                                                    onResolve={({ provider, data }) => {
                                                        setProfile(data)
                                                        handleSocialLogin(data, 2)
                                                    }}
                                                    onReject={(err) => {
                                                        console.log(err)
                                                    }}
                                                >
                                                    <img src={GoogleLogo} alt="Google" /> {t('sign_in_with_google')}
                                                </LoginSocialGoogle>
                                            </SocialButton>
                                            <DontAccount>{t('already_a_member')} <a onClick={() => setIsLogin(true)}>{t('login')}</a></DontAccount>
                                            <p style={{ color: '#adaaaa', marginTop: '40px' }}>{t('ev_account_can_be')}</p>
                                        </SignupTemplate>
                                }
                            </>
                        }
                        {!openOtp && !openReset && openForget &&
                            <ForgotTemplate>
                                <h1 style={{ marginBottom: '15vh', fontSize: '2rem' }}><img src={DrLogo} alt="" style={{ width: '100px', filter: 'invert(1)' }} /></h1>
                                <h2>{t('forget_password')}</h2>
                                <p style={{ marginBottom: '40px' }}>Enter your email address and we will send an one time passowrd (OTP) to reset your password.</p>
                                <InputField
                                    error={errorsForgot.emailForgot}
                                    onChange={onchangeHandlerForgot("emailForgot")}
                                    value={emailForgot}
                                    marginBottom={20}
                                    placeholder={t('email')}
                                    label={t('email')}
                                    color="#222"
                                />
                                <CustomButton
                                    loading={loadingForgot}
                                    onClickHandler={handleSaveForgotEmail}
                                    marginTop={12}
                                    marginBottom={40}
                                    width="100%"
                                    label={t('submit')}

                                />
                                <a onClick={() => handleForgotBack()}>{t('back')}</a>

                            </ForgotTemplate>

                        }

                        {!openReset && !openForget && openOtp &&

                            <OTPTemplate>
                                <h2>An OTP sent to your email</h2>
                                <p style={{ marginBottom: '50px' }}>Please check your email inbox or spam folders for tha the OTP email.</p>
                                <div className='__otp_input'>
                                    <OTPInput
                                        value={otp}
                                        onChange={handleOnChangeOTP}
                                        numInputs={4}
                                        renderInput={(props: any) => <input  {...props} />}
                                    />
                                </div>

                                <CustomButton
                                    loading={isLoadingOTP}
                                    onClickHandler={handleSaveOTP}
                                    marginTop={50}
                                    marginBottom={40}
                                    width="140px"
                                    label={t('submit')}
                                />
                                <a onClick={() => handleCancelOTP()}>{t('cancel')}</a>
                            </OTPTemplate>
                        }

                        {!openOtp && !openForget && openReset &&
                            <ForgotTemplate>
                                <h1 style={{ marginBottom: '15vh', fontSize: '2rem' }}><img src={DrLogo} alt="" style={{ width: '100px', filter: 'invert(1)' }} /></h1>
                                <h2 style={{ marginBottom: '40px' }}>{t('reset_password')}</h2>

                                <InputField
                                    error={errorsReset.newPassword}
                                    onChange={onchangeHandlerReset("newPassword")}
                                    value={newPassword}
                                    placeholder={t('new_password')}
                                    label={t('new_password')}
                                    type="password"
                                    color="#222"
                                />

                                <InputField
                                    error={errorsReset.confirmPassword}
                                    onChange={onchangeHandlerReset("confirmPassword")}
                                    value={confirmPassword}
                                    placeholder={t('confirm_password')}
                                    label={t('confirm_password')}
                                    type="password"
                                    color="#222"
                                />
                                <CustomButton
                                    loading={loadingReset}
                                    onClickHandler={handleSaveResetPassword}
                                    marginTop={12}
                                    marginBottom={40}
                                    width="100%"
                                    label={t('submit')}
                                />
                                <a onClick={() => handleResetBack()}>{t('back')}</a>

                            </ForgotTemplate>
                        }

                    </RightWrap>
                </LoginWrapper>
            </Modal>
        </>
    );
};

export default Login;

Login.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
};
