import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Languages
import english from './eng.json';
import arabic from './ar.json';

interface InitOptions {
  resources: Record<string, { translations: Record<string, string> }>;
  fallbackLng: string;
  debug: boolean;
  ns: string[];
  defaultNS: string;
  keySeparator: boolean;
  interpolation: {
    escapeValue: boolean;
    formatSeparator: string;
  };
}

i18n
  .use(LanguageDetector)
  .init<InitOptions>({
    resources: {
      en: {
        translations: english
      },
      ar: {
        translations: arabic
      }
    },
    fallbackLng: 'ar',
    debug: true,

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ','
    },
  });

export default i18n;
