import { colors } from "assets/styles/colors";
import { Grid } from "assets/styles/globalStyles";
import { FontSizes } from "utils/enums";
import styled from "styled-components";


export const HeaderOuter = styled.div`
        width:100%;
        padding:30px;
        position: fixed;
        top: 0;
        z-index:11;
        .__drawer{
            display: flex;
            flex-direction:column;
            gap:25px;
        }
  `;
export const DrawerItems = styled.div<any>`
      display: flex;
      flex-direction:column;
      gap:15px;
      direction:${(props) => props.direction};
      a{
        color:#000;
        font-size:20px;
        display:flex;
        gap:10px;
        align-items:center;
      }
      .active {
        font-weight: 600;
        color: #dab2ff;
        svg{
            fill: #dab2ff;
        }
    }

    
    .__border-bottom{
        border-bottom : 1px solid #ddd;
        margin-bottom: 10px;
        padding-bottom:10px;
    }

    svg{
        width: 18px;
        height: 18px;
    }
  
`;

export const HeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff2e;
    padding: 5px 40px;
    border-radius: 140px;
    color: #fff;
    backdrop-filter: blur(10px);

    button{
        color: #fff;
        border: none;
        background: #794BA3;
        font-weight: 700;
        border-radius: 47px;
        font-size: 17px;
        height: 40px;
        width: 41px;
        display: flex;
        justify-content: center;
        align-items: center;
    }


  `;

export const TopMenu = styled.div<any>`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    @media (max-width: 556px) {
        display: none;
    }

        ${(props) => props.direction === 'rtl' ?
        `a{
            color:#fff;
            font-size:20px;
        }` :
        `a{
            color:#fff;
            font-size: 14px;
            font-style: normal;
            font-weight: 200;
            line-height: 22px;
            letter-spacing: .01em;
        }`
    }
        
        .active{
            font-weight:600;
            color:#dab2ff;
            
        }
`;

export const LogoText = styled.a`
    font-size: 20px;
    font-weight: 600;
    color:#fff !important;
    display:flex;
    gap:10px;
    align-items:center;
    a{
        color:#fff !important;
    }
    img{
        width:100px;
        @media (max-width: 556px) {
            width: 70px;
        }
    }
}
`

export const RightMenu = styled.div<any>`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    ${(props) => props.direction === 'rtl' ?
        `a{
            font-size:20px;
        }` :
        `a{
            font-size: 14px;
            font-style: normal;
            font-weight: 200;
            line-height: 22px;
            letter-spacing: .01em;
        }`
    }

        a{
            color:#fff;
            display: flex;
            align-items: center;
            justify-content: center;
            gap:5px;
            span{
                font-size:14px;
            }
    }
`;