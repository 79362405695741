import { colors } from "assets/styles/colors";
import { Grid } from "assets/styles/globalStyles";
import { FontSizes } from "utils/enums";
import styled from "styled-components";
import BackgroundImg from 'assets/images/back_1.webp'

export const LoginWrapper = styled.div`
    width:100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    height:100vh;
    justify-content:center;
    align-items:center;    
`

export const LeftWrap = styled.div`
    background: url(${BackgroundImg});
    height:100vh;
    background-size: cover;
    padding: 70px;
    color: #fff;
    display:flex;
    flex-direction:column;
    justify-content: space-between;
    h1{
        font-size: 60px;
        font-weight: 200;
        margin-top: 40px;
        text-shadow: 0px 0px 10px #0002;
    }
    h4{
        font-size: 26px;
        font-weight: 500;
        text-shadow: 0px 0px 10px #0002;
    }


`
export const RightWrap = styled.div<any>`
position:relative;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
direction:${(props) => props.direction};
.__svg_cls{
    position:absolute;
    width: 100%;
    height: 100vh;
}

`

export const LoginTemplate = styled.div`
width:50%;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
z-index: 1111;
h2{
    text-align: center;
}
button{
    svg{
        fill:#fff;
    }
}

`

export const ForgotPassword = styled.p`
margin-top: 15px;
    text-align: right;
    width: 100%;
`

export const SocialButton = styled.button`
    width: 100%;
    height: 44px;
    display: flex;
    gap: 15px;
    border-radius: 8px;
    border: 1px solid #D0D5DD;
    margin-top: 12px;
    justify-content: center;
    font-weight: 500;
    background: #fff;
    padding: 0;
    div{
        display: flex;
        gap: 15px;
        justify-content: center;
        align-items:center;
        width:100%;
        height:100%;
    }

`

export const DontAccount = styled.div`
margin-top: 35px;
    text-align: center;
    width: 100%;
    margin-bottom:15px;
    font-size:15px;
    a{
        color:${colors?.primary} !important;
        font-weight:bold ;
    }
`


export const SignupTemplate = styled.div`
width:50%;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
z-index: 1111;

`


export const OTPTemplate = styled.div`
width:70%;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
text-align:center;
z-index: 1111;
.__otp_input input {
    width: 40px !important;
    margin: 0 6px;
    height: 40px;
    border: 1px solid #D0D5DD;
    border-radius: 10px;
}

`


export const ForgotTemplate = styled.div`
width:35%;
max-width:380px;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
text-align:center;
z-index: 1111;
.__otp_input input {
    width: 40px !important;
    margin: 0 6px;
    height: 40px;
    border: 1px solid #D0D5DD;
    border-radius: 10px;
}

`